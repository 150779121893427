import * as Progress from '@radix-ui/react-progress'

interface Props {
  label?: string
  showValueLabel?: boolean
  value: number
  valueLabel?: string
}

export const ProgressBar: React.FC<Props> = (props) => {
  let { label, showValueLabel, value, valueLabel } = props

  if (value > 100) value = 100

  return (
    <div>
      <div className='mb-2 flex justify-between'>
        {label && <span>{label}</span>}
        {showValueLabel && <span>{valueLabel}</span>}
      </div>
      <Progress.Root
        className='h-3 overflow-hidden rounded-lg bg-gray-500/50'
        value={value}
        getValueLabel={(value, max) => valueLabel ?? `${value} of ${max}`}
      >
        <Progress.Indicator className='h-3 bg-primary-600 transition-all' style={{ transform: `translateX(-${100 - value}%)` }} />
      </Progress.Root>
    </div>
  )
}
